
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as quote_45bdm6IVX93xAIIh46R9Tp_45_457NfJJnwv6DzEUgqxUwHQd6_sMeta } from "/opt/buildhome/repo/pages/agents-breakdown/[name]/quote-bdm.vue?macro=true";
import { default as quote_45giverdVeQ9Sc4MAtOVaqnj5lsAR2y_4550u_l6rEl_QmMYuzo0Meta } from "/opt/buildhome/repo/pages/agents-breakdown/[name]/quote-giver.vue?macro=true";
import { default as indexQLbd_45_VEGEymTy61om4OM78JfxLHuFgCIu_qxD7KdRoMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexneuKU5tbZsfdrswJ8AhvzCZF_45xZ_Nl_45dkyix6KPb6MoMeta } from "/opt/buildhome/repo/pages/login/index.vue?macro=true";
import { default as microsoft_45callbackCljrhWSQUCt4O3gqE6Z_QKSNWwDMsgX4B6hmmShasBcMeta } from "/opt/buildhome/repo/pages/login/microsoft-callback.vue?macro=true";
import { default as draft_45editPEv2XsFW5CGK6_45PRgjJcNkdZmWMYzY8_DVXJmEtFukEMeta } from "/opt/buildhome/repo/pages/quote-cases/[id]/draft-edit.vue?macro=true";
import { default as editQMgHxrTWfHLIU6jWXTPA4ZjkJ8M4_7nTXvFWGGPgKYUMeta } from "/opt/buildhome/repo/pages/quote-cases/[id]/edit.vue?macro=true";
import { default as indexcWXxR5Wzv3NfyBnWr57ljWLWY1HC4ecMV1iGdZKTx6cMeta } from "/opt/buildhome/repo/pages/quote-cases/[id]/index.vue?macro=true";
import { default as agent_45target_45stats_45detailsW_XuTjWz3sJTLut1uIXg08wM_45yBdLIt6UzSFyoVSQmkMeta } from "/opt/buildhome/repo/pages/quote-cases/agent-target-stats-details.vue?macro=true";
import { default as agent_45target_45statsPIwgHk62jsANwrcIDFcTp6BQC0sG_W3ICk_45KcRV9HOoMeta } from "/opt/buildhome/repo/pages/quote-cases/agent-target-stats.vue?macro=true";
import { default as call_45logspYlE_BB_45wtMTyQBuqmhKKH9IxXZ6b4nKyizJP2IektgMeta } from "/opt/buildhome/repo/pages/quote-cases/call-logs.vue?macro=true";
import { default as call_45statussKeQr3v90VQFqqIgS5ibzA6O_45qQ4v7B7Pcv1W0COp4cMeta } from "/opt/buildhome/repo/pages/quote-cases/call-status.vue?macro=true";
import { default as create4HRL0Q_SiGfbFAXjKDejgcDGQK7wHtI6ILBL2OJfztAMeta } from "/opt/buildhome/repo/pages/quote-cases/create.vue?macro=true";
import { default as dead_45cases_45reasonTlOAfW1yFMUFVZxbEV3fVWmB8DIjkMH9IAtwT8sxLXYMeta } from "/opt/buildhome/repo/pages/quote-cases/dead-cases-reason.vue?macro=true";
import { default as dead_45tomorrowdRss9ZvELHhsiXDvMTJZwZVUYsFde6bqqztsSnHeJaQMeta } from "/opt/buildhome/repo/pages/quote-cases/dead-tomorrow.vue?macro=true";
import { default as drafts3jkKnFYJw0vsSYvAgVZa205TTDP2MedAi071mrIG2QAMeta } from "/opt/buildhome/repo/pages/quote-cases/drafts.vue?macro=true";
import { default as logsryJG8G6yyiW7TahxcSNktCmHSrmDVcLtHcrMXzRv2NQMeta } from "/opt/buildhome/repo/pages/quote-cases/logs.vue?macro=true";
import { default as presold_45quote_45listR9gmBvgPDVAOTv9Db6_45_45vhvkN8OJOMorvWVOwqLqXMMMeta } from "/opt/buildhome/repo/pages/quote-cases/presold-quote-list.vue?macro=true";
import { default as quote_45monthly_45giver_45statsqVVlFB9sIuuHGEkvIEVTQsNY1fs4zQw4odeiqDp1MuEMeta } from "/opt/buildhome/repo/pages/quote-cases/quote-monthly-giver-stats.vue?macro=true";
import { default as sold_45quote_45listEqTFpCqOXUWBq2kjMBsOpSyAf91bHpEnHeoCEawnJoQMeta } from "/opt/buildhome/repo/pages/quote-cases/sold-quote-list.vue?macro=true";
import { default as stats1ZYyThZgsxpoVnBZVXByzOHg5Om6O6ZYRhUDL24apigMeta } from "/opt/buildhome/repo/pages/quote-cases/stats.vue?macro=true";
import { default as _91graph_935mC7dGVo4av4aK065_AmE5cH0QryfJC_npc7RgnjeH0Meta } from "/opt/buildhome/repo/pages/quote-cases/track-emails/[id]/email/[graph].vue?macro=true";
import { default as index3iJnN9iqXYq6IeUU_45fNp_45EPaVd9Rk0W4HjS02CwgVNgMeta } from "/opt/buildhome/repo/pages/quote-cases/track-emails/[id]/index.vue?macro=true";
import { default as _91id_93zuKgHTR3XOPt_45dTq4M93eALVUAFzwN4hhgLSaAjQRkUMeta } from "/opt/buildhome/repo/pages/quote-leads/[id].vue?macro=true";
import { default as createKQY2xPldlu_45erFu5UY6ypGC3dkBKhcQZX0ag_45ptoJ00Meta } from "/opt/buildhome/repo/pages/quote-leads/create.vue?macro=true";
import { default as presoldjOQOVzNIcg_uGEkFU4BfZ7qG7sioXUjFpXAWOXm8Rf0Meta } from "/opt/buildhome/repo/pages/quote-leads/presold.vue?macro=true";
import { default as soldNa9qI98zrQLuUq6vwixbDzqyA4aCXghuvC8wzZmmC98Meta } from "/opt/buildhome/repo/pages/quote-leads/sold.vue?macro=true";
import { default as completion_45target_45report8_45EIBU_45tI4xlYU3lPWH_yCxDIEy0s6TmJNwYDQD4qg0Meta } from "/opt/buildhome/repo/pages/reports/completion-target-report.vue?macro=true";
import { default as performance_45reportuLXEXeig7wv7V0YGQq6TG8ImTGeZ6v9YBk4fA1sAzU8Meta } from "/opt/buildhome/repo/pages/reports/performance-report.vue?macro=true";
import { default as indexXap3wfXAqrD1ugm_EqECHSHbYZJLm3fLYk0rp2QVtN0Meta } from "/opt/buildhome/repo/pages/requisition-replies/index.vue?macro=true";
import { default as createNkUJ_lUbgluBhrTjtt_45e0oxqTwjN6Av_45UZ5pb44fapYMeta } from "/opt/buildhome/repo/pages/sale-memos/create.vue?macro=true";
import { default as indexfwk_45fqFKz_kOhy5xmnz7yLgQOfcYoXMNacgyoHyboX8Meta } from "/opt/buildhome/repo/pages/sale-memos/index.vue?macro=true";
import { default as editMto_TXpGMOWNBdeflZDXrxIWYIyS9WAxqpkqVR2ct7UMeta } from "/opt/buildhome/repo/pages/sale-ready-pack/[id]/edit.vue?macro=true";
import { default as indexqN8C7rHx5Yc4nXozk9m666cT6i_8mlMUZitrmaRUq5kMeta } from "/opt/buildhome/repo/pages/sale-ready-pack/[id]/index.vue?macro=true";
import { default as create4aV3YnzhCXV8_RrjhUd_45IcuxcFUn3nI1zxaCfqBjOkoMeta } from "/opt/buildhome/repo/pages/sale-ready-pack/create.vue?macro=true";
import { default as indexI_3SlDZkYKQ9MmFqmkolZYzLnunZRfEcaubZ7DCokNAMeta } from "/opt/buildhome/repo/pages/sale-ready-pack/index.vue?macro=true";
import { default as indexie3dybyrT_ARKia9FcOHDgUR81XhQGB3SFKNMVRv3HAMeta } from "/opt/buildhome/repo/pages/whatsapp/index.vue?macro=true";
import { default as component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "agents-breakdown-name-quote-bdm",
    path: "/agents-breakdown/:name()/quote-bdm",
    component: () => import("/opt/buildhome/repo/pages/agents-breakdown/[name]/quote-bdm.vue")
  },
  {
    name: "agents-breakdown-name-quote-giver",
    path: "/agents-breakdown/:name()/quote-giver",
    component: () => import("/opt/buildhome/repo/pages/agents-breakdown/[name]/quote-giver.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexQLbd_45_VEGEymTy61om4OM78JfxLHuFgCIu_qxD7KdRoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexneuKU5tbZsfdrswJ8AhvzCZF_45xZ_Nl_45dkyix6KPb6MoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login/index.vue")
  },
  {
    name: "login-microsoft-callback",
    path: "/login/microsoft-callback",
    component: () => import("/opt/buildhome/repo/pages/login/microsoft-callback.vue")
  },
  {
    name: "quote-cases-id-draft-edit",
    path: "/quote-cases/:id()/draft-edit",
    meta: draft_45editPEv2XsFW5CGK6_45PRgjJcNkdZmWMYzY8_DVXJmEtFukEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/[id]/draft-edit.vue")
  },
  {
    name: "quote-cases-id-edit",
    path: "/quote-cases/:id()/edit",
    component: () => import("/opt/buildhome/repo/pages/quote-cases/[id]/edit.vue")
  },
  {
    name: "quote-cases-id",
    path: "/quote-cases/:id()",
    meta: indexcWXxR5Wzv3NfyBnWr57ljWLWY1HC4ecMV1iGdZKTx6cMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/[id]/index.vue")
  },
  {
    name: "quote-cases-agent-target-stats-details",
    path: "/quote-cases/agent-target-stats-details",
    meta: agent_45target_45stats_45detailsW_XuTjWz3sJTLut1uIXg08wM_45yBdLIt6UzSFyoVSQmkMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/agent-target-stats-details.vue")
  },
  {
    name: "quote-cases-agent-target-stats",
    path: "/quote-cases/agent-target-stats",
    meta: agent_45target_45statsPIwgHk62jsANwrcIDFcTp6BQC0sG_W3ICk_45KcRV9HOoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/agent-target-stats.vue")
  },
  {
    name: "quote-cases-call-logs",
    path: "/quote-cases/call-logs",
    meta: call_45logspYlE_BB_45wtMTyQBuqmhKKH9IxXZ6b4nKyizJP2IektgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/call-logs.vue")
  },
  {
    name: "quote-cases-call-status",
    path: "/quote-cases/call-status",
    meta: call_45statussKeQr3v90VQFqqIgS5ibzA6O_45qQ4v7B7Pcv1W0COp4cMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/call-status.vue")
  },
  {
    name: "quote-cases-create",
    path: "/quote-cases/create",
    meta: create4HRL0Q_SiGfbFAXjKDejgcDGQK7wHtI6ILBL2OJfztAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/create.vue")
  },
  {
    name: "quote-cases-dead-cases-reason",
    path: "/quote-cases/dead-cases-reason",
    component: () => import("/opt/buildhome/repo/pages/quote-cases/dead-cases-reason.vue")
  },
  {
    name: "quote-cases-dead-tomorrow",
    path: "/quote-cases/dead-tomorrow",
    meta: dead_45tomorrowdRss9ZvELHhsiXDvMTJZwZVUYsFde6bqqztsSnHeJaQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/dead-tomorrow.vue")
  },
  {
    name: "quote-cases-drafts",
    path: "/quote-cases/drafts",
    meta: drafts3jkKnFYJw0vsSYvAgVZa205TTDP2MedAi071mrIG2QAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/drafts.vue")
  },
  {
    name: "quote-cases-logs",
    path: "/quote-cases/logs",
    meta: logsryJG8G6yyiW7TahxcSNktCmHSrmDVcLtHcrMXzRv2NQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/logs.vue")
  },
  {
    name: "quote-cases-presold-quote-list",
    path: "/quote-cases/presold-quote-list",
    meta: presold_45quote_45listR9gmBvgPDVAOTv9Db6_45_45vhvkN8OJOMorvWVOwqLqXMMMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/presold-quote-list.vue")
  },
  {
    name: "quote-cases-quote-monthly-giver-stats",
    path: "/quote-cases/quote-monthly-giver-stats",
    component: () => import("/opt/buildhome/repo/pages/quote-cases/quote-monthly-giver-stats.vue")
  },
  {
    name: "quote-cases-sold-quote-list",
    path: "/quote-cases/sold-quote-list",
    meta: sold_45quote_45listEqTFpCqOXUWBq2kjMBsOpSyAf91bHpEnHeoCEawnJoQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/sold-quote-list.vue")
  },
  {
    name: "quote-cases-stats",
    path: "/quote-cases/stats",
    meta: stats1ZYyThZgsxpoVnBZVXByzOHg5Om6O6ZYRhUDL24apigMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-cases/stats.vue")
  },
  {
    name: "quote-cases-track-emails-id-email-graph",
    path: "/quote-cases/track-emails/:id()/email/:graph()",
    component: () => import("/opt/buildhome/repo/pages/quote-cases/track-emails/[id]/email/[graph].vue")
  },
  {
    name: "quote-cases-track-emails-id",
    path: "/quote-cases/track-emails/:id()",
    component: () => import("/opt/buildhome/repo/pages/quote-cases/track-emails/[id]/index.vue")
  },
  {
    name: "quote-leads-id",
    path: "/quote-leads/:id()",
    meta: _91id_93zuKgHTR3XOPt_45dTq4M93eALVUAFzwN4hhgLSaAjQRkUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-leads/[id].vue")
  },
  {
    name: "quote-leads-create",
    path: "/quote-leads/create",
    meta: createKQY2xPldlu_45erFu5UY6ypGC3dkBKhcQZX0ag_45ptoJ00Meta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-leads/create.vue")
  },
  {
    name: "quote-leads-presold",
    path: "/quote-leads/presold",
    meta: presoldjOQOVzNIcg_uGEkFU4BfZ7qG7sioXUjFpXAWOXm8Rf0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-leads/presold.vue")
  },
  {
    name: "quote-leads-sold",
    path: "/quote-leads/sold",
    meta: soldNa9qI98zrQLuUq6vwixbDzqyA4aCXghuvC8wzZmmC98Meta || {},
    component: () => import("/opt/buildhome/repo/pages/quote-leads/sold.vue")
  },
  {
    name: "reports-completion-target-report",
    path: "/reports/completion-target-report",
    meta: completion_45target_45report8_45EIBU_45tI4xlYU3lPWH_yCxDIEy0s6TmJNwYDQD4qg0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/reports/completion-target-report.vue")
  },
  {
    name: "reports-performance-report",
    path: "/reports/performance-report",
    meta: performance_45reportuLXEXeig7wv7V0YGQq6TG8ImTGeZ6v9YBk4fA1sAzU8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/reports/performance-report.vue")
  },
  {
    name: "requisition-replies",
    path: "/requisition-replies",
    meta: indexXap3wfXAqrD1ugm_EqECHSHbYZJLm3fLYk0rp2QVtN0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/requisition-replies/index.vue")
  },
  {
    name: "sale-memos-create",
    path: "/sale-memos/create",
    meta: createNkUJ_lUbgluBhrTjtt_45e0oxqTwjN6Av_45UZ5pb44fapYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sale-memos/create.vue")
  },
  {
    name: "sale-memos",
    path: "/sale-memos",
    meta: indexfwk_45fqFKz_kOhy5xmnz7yLgQOfcYoXMNacgyoHyboX8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/sale-memos/index.vue")
  },
  {
    name: "sale-ready-pack-id-edit",
    path: "/sale-ready-pack/:id()/edit",
    meta: editMto_TXpGMOWNBdeflZDXrxIWYIyS9WAxqpkqVR2ct7UMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sale-ready-pack/[id]/edit.vue")
  },
  {
    name: "sale-ready-pack-id",
    path: "/sale-ready-pack/:id()",
    meta: indexqN8C7rHx5Yc4nXozk9m666cT6i_8mlMUZitrmaRUq5kMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sale-ready-pack/[id]/index.vue")
  },
  {
    name: "sale-ready-pack-create",
    path: "/sale-ready-pack/create",
    meta: create4aV3YnzhCXV8_RrjhUd_45IcuxcFUn3nI1zxaCfqBjOkoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sale-ready-pack/create.vue")
  },
  {
    name: "sale-ready-pack",
    path: "/sale-ready-pack",
    meta: indexI_3SlDZkYKQ9MmFqmkolZYzLnunZRfEcaubZ7DCokNAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/sale-ready-pack/index.vue")
  },
  {
    name: "whatsapp",
    path: "/whatsapp",
    component: () => import("/opt/buildhome/repo/pages/whatsapp/index.vue")
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/sign-in",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  },
  {
    name: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJoMeta?.name,
    path: "/quotes",
    component: component_45stub_450o40pIUSw8QaPELG0OvfZH1GwyPyIaXd7Bv_45sPiDJo
  }
]