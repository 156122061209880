import { defineStore } from 'pinia'
import { useSorts } from '~/composables/useSorts.js'
import axiosService from '~/services/axios.service.js'

export const useQuotesStore = defineStore({
  id: 'quotes',
  persist: {
    pick: ['loadDefaultFilters'],
  },
  state: () => ({
    draftQuoteCases: {
      data: [],
      links: {},
      meta: {},
    },
    draft: {},
    quoteCases: {
      data: [],
      links: {},
      meta: {},
      totals: {},
    },
    quoteCasesStats: {},
    quoteGiversStats: [],
    quoteGiversMonthlyStats: [],
    quoteBDMStats: [],
    quoteBDMAgentsStats: {
      data: [],
      links: {},
      meta: {},
    },
    quoteGiversAgentStats: {
      data: [],
      links: {},
      meta: {},
    },
    quoteCallLogStats: [],
    quoteStats: {
      instructed: {},
      pending: {},
      dead: {},
      total: {},
    },
    callLogPreview: {},
    quoteRequest: {},
    quoteRequests: {
      data: [],
      links: {},
      meta: {},
    },
    quoteStatsRequests: {},
    quoteCallLogs: {
      data: [],
      links: {},
      meta: {},
    },
    quoteCallLogsTempRecords: [],
    quoteLogs: {
      data: [],
      links: {},
      meta: {},
    },
    quoteLogStats: [],
    loadDefaultFilters: true,
  }),
  actions: {
    async fetchDraftQuoteCases(params = {}) {
      const queryParams = useFilters(params)
      return await axiosService
        .get('/draft-quotes', { params: queryParams })
        .then((response) => {
          this.draftQuoteCases.data = response.data
          this.draftQuoteCases.links = response.links
          this.draftQuoteCases.meta = response.meta
        })
        .catch((err) => {
          throw err
        })
    },
    async deleteDraftQuote(id) {
      return await axiosService.delete(`/draft-quotes/${id}`).catch((err) => {
        throw err
      })
    },
    async getDraftQuote(id) {
      return await axiosService
        .get(`/draft-quotes/${id}`)
        .then((response) => (this.draft = response))
        .catch((err) => {
          throw err
        })
    },
    async fetchQuoteCases(params = {}) {
      const queryParams = useFilters(params)
      return await axiosService
        .get('/quote-cases', { params: queryParams })
        .then((response) => {
          this.quoteCases.data = response.data
          this.quoteCases.links = response.links
          this.quoteCases.meta = response.meta
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchQuoteCasesWithCallLogs(params = {}) {
      const queryParams = useFilters(params)
      return await axiosService
        .get('/quote-call-logs-status', { params: queryParams })
        .then((response) => {
          this.quoteCases.data = response.quote_cases.data
          this.quoteCases.links = response.quote_cases.links
          this.quoteCases.meta.total = response.quote_cases.total
          this.quoteCases.totals = response.totals
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchQuoteCallLogStats(params = {}) {
      const queryParams = useFilters(params)
      return await axiosService
        .get('/quote-call-logs/stats', { params: queryParams })
        .then((response) => {
          this.quoteCallLogStats = response
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchQuoteCasesStats(params = {}) {
      const queryParams = useFilters(params)
      return await axiosService
        .get('/quote-cases/stats', { params: queryParams })
        .then((response) => {
          this.quoteCasesStats = response
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchQuoteGiverStats(params = {}) {
      const queryParams = useFilters(params)
      return await axiosService
        .get('/quote-givers/stats', { params: queryParams })
        .then((response) => {
          this.quoteGiversStats = response
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchQuoteGiverAgentsStats(params = {}) {
      const queryParams = useFilters(params)
      return await axiosService
        .get('/quote-givers-agents/stats', { params: queryParams })
        .then((response) => {
          this.quoteGiversAgentStats = response
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchQuoteGiverMonthlyStats(params = {}) {
      const queryParams = useFilters(params)
      return await axiosService
        .get('/quote-givers-monthly/stats', { params: queryParams })
        .then((response) => {
          this.quoteGiversMonthlyStats = response
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchQuoteBDMStats(params = {}) {
      const queryParams = useFilters(params)
      return await axiosService
        .get('/quote-bdms/stats', { params: queryParams })
        .then((response) => {
          this.quoteBDMStats = response
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchAgentTargetStats(params = {}) {
      const queryParams = useFilters(params)
      return await axiosService
        .get('/agent-quote-target/stats', { params: queryParams })
        .then((response) => {
          return response
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchQuoteBDMAgentsStats(params = {}) {
      const queryParams = useFilters(params)
      return await axiosService
        .get('/quote-bdms-agents/stats', { params: queryParams })
        .then((response) => {
          this.quoteBDMAgentsStats.data = response.data
          this.quoteBDMAgentsStats.links = response.links
          this.quoteBDMAgentsStats.meta = response.meta
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchQuoteRequests(filters = {}) {
      const params = useSorts(filters)
      this.quoteRequests = await axiosService.get('/quote-requests', { params })
    },
    async fetchQuoteStatsRequests(filters = {}) {
      const params = useSorts(filters)
      this.quoteStatsRequests = await axiosService.get('/quote-stats-requests', { params })
    },
    async fetchQuoteCallLogs(filters = {}) {
      const params = useFilters(filters)
      this.quoteCallLogs = await axiosService.get('/quote-call-logs', { params })
    },
    async fetchQuoteLogs(filters = {}) {
      const params = useFilters(filters)
      this.quoteLogs = await axiosService.get('/quote-case-logs', { params })
    },
    async fetchQuoteLogStats(params = {}) {
      const queryParams = useFilters(params)
      return await axiosService
        .get('/quote-case-logs/stats', { params: queryParams })
        .then((response) => {
          this.quoteLogStats = response
        })
        .catch((err) => {
          throw err
        })
    },
    async addQuoteCallLog(formData) {
      return await axiosService
        .post('/quote-call-logs', formData)
        .then((response) => {
          this.quoteCallLogsTempRecords = []
        })
        .catch((err) => {
          throw err
        })
    },
    async updateQuoteCallLog(formData) {
      return await axiosService
        .put('/quote-call-logs-update-id', formData)
        .then((response) => {
          this.quoteCallLogsTempRecords = []
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchQuoteRequest(id) {
      this.quoteRequest = await axiosService.get(`/quote-requests/${id}`)
    },
    async fetchQuoteStats(params = {}) {
      return await axiosService
        .get('/stats/quotes', { params })
        .then((response) => {
          this.quoteStats = response
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchQuoteCallLogPreview(id) {
      return await axiosService
        .get(`/quote-cases/${id}/call-log`)
        .then((response) => {
          this.callLogPreview = response
        })
        .catch((err) => {
          this.callLogPreview = {}
          throw err
        })
    },
    async requestQuote(formData) {
      return await axiosService
        .post('/quote-requests', formData)
        .then((response) => {
          this.quoteRequest = response
        })
        .catch((err) => {
          throw err
        })
    },
    disableLoadDefaultFilters() {
      this.loadDefaultFilters = false
    },
    enableLoadDefaultFilters() {
      this.loadDefaultFilters = true
    },
  },
})
